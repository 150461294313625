li {
  list-style: none;
  flex-grow: 4;
}

.eye-btn {
  max-width: 100%;
  cursor: pointer;
}

.subtitle {
  justify-content: center;
  font-weight: 100;
  font-family: "Fraunces";
  margin-bottom: 50px;
  margin-top: -30px;
  font-style: italic;
}

#author {
  text-transform: uppercase;
  color: white;
  text-align: right;
}

.break {
  display: block;
  background-color: #f73bb2;
  height: 1px;
  border: none;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  animation-name: zoom;
  animation-duration: 0.6s;
  position: relative;
  object-fit: contain;
  padding: 5px;
  max-width: 100%;
  /* overflow: auto;
  height: 100%; */
  min-height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  position: relative;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  padding: 5px;
  overflow: auto;
  height: 100%;
}

iframe {
  width: 280px;
  height: 157px;
}

.like {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  font-size: 20px;
  font-weight: lighter;
  padding-top: 10px;
}

.comment {
  padding: 2px;
  color: white;
}

textarea {
  width: 90%;
}

/* The Close Button */
.close {
  position: fixed;
  top: 10px;
  right: 10px;
  font-size: 40px;
  font-weight: bold;
  color: white;
  background: black;
  border: none;
}

.close:hover,
.close:focus {
  color: #f73bb2;
  text-decoration: none;
  cursor: pointer;
}

.comment-submit {
  margin-top: -15px;
}

@media only screen and (min-width: 380px) {
  .modal-container {
    width: 364px;
  }
  iframe {
    width: 364px;
    height: 204px;
  }
}
@media only screen and (min-width: 560px) {
  .modal-container {
    width: 560px;
  }
  iframe {
    width: 560px;
    height: 315px;
  }
}
@media only screen and (min-width: 728px) {
  .modal-container {
    width: 728px;
  }
  iframe {
    width: 728px;
    height: 409px;
  }
}
