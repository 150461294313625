.About {
  text-align: center;
}

.about-img {
  max-width: 100%;
  width: 800px;
  margin: 0 auto;
  margin-top: 30px;
}

.about-paragraph {
  font-family: "Fraunces", serif;
  font-size: 1.5em;
  max-width: 600px;
  min-width: 280px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 100px;
}

.name {
  color: #eb6534;
  font-weight: bold;
  text-transform: uppercase;
}

@media only screen and (min-width: 600px) {
  .about-paragragh {
    padding: 15px;
  }
}
