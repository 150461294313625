.auth-link {
  text-transform: uppercase;
  font-size: 42px;
  background: white;
  margin-top: -1px;
  cursor: pointer;
}

.auth-link:hover {
  color: #f73bb2;
}

@media only screen and (max-width: 1000px) {
  .auth-link {
    border: none;
    text-transform: uppercase;
    font-size: 38px;
    color: white;
    background: #f73bb2;
    padding: 10px;
    margin-left: 10px;
    margin-top: 20px;
    max-width: 150px;
  }

  .auth-link:hover {
    color: black;
  }
}
