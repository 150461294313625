.navigation-link {
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 42px;
  }

.navigation-link {
    color: #F73BB2;
  }
  .fa-bars {
    display: none;
    color: black;
    font-size: 2rem;
  }

  .nav ul {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.nav li {
    font-size: 42px;
    color: #000000;
    text-decoration: none;
}

li:hover {
    color: #f73bb2;
}



  @media only screen and (max-width: 1000px) {
    .nav {
    height: auto;
    min-height: 50px;
    display: block;
    position: relative;
    }
    
    .fa-bars {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    .nav ul.collapsed {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow: hidden;
      max-height: 0;
    }
      

    .nav ul.is-expanded {
        overflow: hidden;
        max-height: 500px; /* approximate max height */
      }

      .nav li {
        padding: 15px 10px;
        margin: 0px 0px;
        width: 100%;
      }

  }