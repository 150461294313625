body {
  margin: 15px;
}

.footer {
  display: flex;
  margin: 0px 80px 50px 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-img {
  min-width: 280px;
  max-width: 100%;
  text-align: center;
  margin-top: 100px;
}

.imageBox {
  position: relative;
  float: left;
}

.imageBox .hoverImg {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.imageInn1 {
  display: none;
}

.imageBox1 .hoverImg1 {
  position: relative;
  display: block;
}

.sore-eyes {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: black;
  font-weight: 300;
  color: #f73bb2;
}

a {
  text-decoration: none;
}

.how-to {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-transform: uppercase;
}

.how-to-desc {
  font-size: 24px;
  max-width: 300px;
  border: 5px solid #D1D646;
  padding: 20px;
  text-align: center;
}

@media (hover: hover) and (pointer: fine) {
  .imageBox:hover .hoverImg {
    display: block;
  }

  .imageInn1 {
    display: block;
  }

  .imageBox1 {
    position: relative;
    float: left;
  }

  .imageBox1 .hoverImg1 {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
  }

  .imageBox1:hover .hoverImg1 {
    display: block;
  }

  .sore-eyes {
    font-size: 55px;
    font-weight: 100;
  }

  .title-img {
    margin-top: 20px;
  }

  .how-to {
    margin-top: 130px;
  }
}

@media only screen and (min-width: 600px) {
  .how-to {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-transform: uppercase;
    margin-top: 150px;
  }
}

@media only screen and (max-width: 900px) {
  .sore-eyes {
    font-size: 30px;
  }

  .how-to {
    margin-top: 130px;
  }
}
