.video__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  justify-items: center;
  grid-gap: 20px;
  align-items: center;
  margin-top: 30px;
  margin-left: -45px;
}

.touch-eye {
  color: #E24E1B;
    text-align: center;
    margin-bottom: 30px;
    font-style: italic;
    animation: blinker 3s linear infinite;
}

.loading {
  padding: 100px 0px 500px 0px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media (hover: hover) and (pointer: fine){
  .touch-eye {
    display: none;
    margin-bottom: 0px;
    
  }
}