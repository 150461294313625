.comment-list {
  margin-bottom: 50px;
  margin-top: 10px;
  margin-left: -38px;
  color: white;
  font-family: "Fraunces";
}

.comment-list-item {
  border: 1px solid #D1D646;
  padding: 10px;
}

#new-comment {
  margin-top: 10px;
}

.comment label {
  margin-bottom: 10px;
}

.comment-submit {
  margin-top: 5px;
  border: none;
  color: black;
  background: white;
}

.comment-submit:hover {
  background: #52d1dc;
}

.user-commenting {
  color: #f73bb2;
}

.user {
  text-transform: uppercase;
  font-weight: lighter;
}
