code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @font-face {
  font-family: 'Bau-SuperItalic';
  src: local('Bau-SuperItalic'), url(./fonts/Bau-SuperItalic.otf) format('otf');
  font-weight: normal;
  font-style: normal;
} */

body {
  margin: 0;
  font-family: "Epilogue", Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
