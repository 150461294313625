.submit {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  font-size: 1.5em;
}

.submit fieldset {
  padding: 10px 12px;
  border: 3px solid #d1d646;
  margin-top: 35px;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}

.submit legend {
  border: none;
  font-size: 40px;
}

.submit label {
  display: block;
  margin-top: 20px;
  margin-bottom: 5px;
}

.submit textarea {
  height: 4.75rem;
  margin-bottom: 20px;
}

.submit button {
  padding: 5px;
  margin-bottom: 20px;
}

.submit h3 {
  font-family: "Fraunces", serif;
}

.submit-desc {
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 20px;
}

.submit-reach-out {
  margin-bottom: 30px;
  font-size: 20px;
}

.submit a {
  text-decoration: none;
  color: black;
}
